// 数字转成大写汉字金额
export function toChineseMoney(money) {
  // 汉字的数字
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖')
  // 基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟')
  // 对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆')
  // 对应小数部分单位
  var cnDecUnits = new Array('角', '分', '毫', '厘')
  //整数金额时后面跟的字符
  var cnInteger = '整'
  //整型完以后的单位
  var cnIntLast = '元'
  // 最大处理的数字
  var maxNum = 999999999999999.9999
  // 金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  // 输出的中文金额字符串
  var chineseStr = ''
  // 分离金额后用的数组，预定义
  var parts
  // 传入的参数为空情况
  if (money == '') { 
    return '' 
  }
  money = parseFloat(money)
  // 超出最大处理数字
  if (money >= maxNum) {    
    return ''
  }
  // 传入的参数为0情况 
  if (money == 0) {
    chineseStr = cnNums[0] + cnIntLast + cnInteger
    return chineseStr
  }
  //转换为字符串
  money = money.toString()
  // 检测某字符在字符串中首次出现的位置 返回索引值（从0 开始） -1 代表无
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0
    var IntLen = integerNum.length
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1)
      var p = IntLen - i - 1
      var q = p / 4
      var m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        //归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    // 最后+ 元
    chineseStr += cnIntLast
  }
  //小数部分
  if (decimalNum != '') {
    var decLen = decimalNum.length
    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1)
      if (n != '0') {
        chineseStr += cnNums[Number(n)] + cnDecUnits[i]
      }
    }
  }
  if (chineseStr == '') {
    chineseStr += cnNums[0] + cnIntLast + cnInteger
  } else if (decimalNum == '') {
    chineseStr += cnInteger
  }
  return chineseStr
}

// 数字转成大写汉字数字
export function toChineseNum(money) {  
  // 汉字的数字
  var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖')
  // 基本单位
  var cnIntRadice = new Array('', '拾', '佰', '仟')
  // 对应整数部分扩展单位
  var cnIntUnits = new Array('', '万', '亿', '兆')
  // 对应小数部分单位
  // var cnDecUnits = new Array('角', '分', '毫', '厘')
  // 整数金额时后面跟的字符
  // var cnInteger = '整'
  // var cnInteger = ''
  //整型完以后的单位
  // var cnIntLast = '元'
  var cnIntLast = '点'
  // 最大处理的数字
  var maxNum = 999999999999999.9999
  // 金额整数部分
  var integerNum;
  //金额小数部分
  var decimalNum;
  // 输出的中文金额字符串
  var chineseStr = ''
  // 分离金额后用的数组，预定义
  var parts
  // 传入的参数为空情况
  // if (money == '') { 
  //   return '' 
  // }
  // console.log('money=', money)
  // money = parseFloat(money)
  // 超出最大处理数字
  if (money >= maxNum) {    
    return ''
  }
  // 传入的参数为0情况  
  if (money == 0) {
    chineseStr = cnNums[0]
    return chineseStr
  }
  //转换为字符串
  money = money.toString()
  // 检测某字符在字符串中首次出现的位置 返回索引值（从0 开始） -1 代表无
  if (money.indexOf('.') == -1) {
    integerNum = money
    decimalNum = ''
  } else {
    parts = money.split('.')
    integerNum = parts[0]
    decimalNum = parts[1].substr(0, 4)
  }
  // console.log('n=', integerNum)
  //获取整型部分转换
  if (parseInt(integerNum, 10) > 0) {
    var zeroCount = 0
    var IntLen = integerNum.length
    for (var i = 0; i < IntLen; i++) {
      var n = integerNum.substr(i, 1)     
      var p = IntLen - i - 1
      var q = p / 4
      var m = p % 4
      if (n == '0') {
        zeroCount++
      } else {
        if (zeroCount > 0) {
          chineseStr += cnNums[0]
        }
        //归零
        zeroCount = 0
        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m]
      }
      if (m == 0 && zeroCount < 4) {
        chineseStr += cnIntUnits[q]
      }
    }
    // 最后+ 点
    if (decimalNum != '') {
      chineseStr += cnIntLast
    }    
  } else if (parseInt(integerNum) == 0) {
    chineseStr = cnNums[0] + cnIntLast
  }
  //小数部分
  if (decimalNum != '') {
    var decLen = decimalNum.length
    for (let i = 0; i < decLen; i++) {
      let n = decimalNum.substr(i, 1)
      // if (n != '0') {
        // chineseStr += cnNums[Number(n)] + cnDecUnits[i]
        chineseStr += cnNums[Number(n)]
      // }
    }
  }
  // if (chineseStr == '') {
  //   chineseStr += cnNums[0] + cnIntLast 
  // } else if (decimalNum == '') {
  //   chineseStr += cnInteger
  // }
  return chineseStr
}

//简单数字转中文数字
export function toChineseSn(num) { 
  // if(num.match(/\D/) || num.length >= 14)
  //   return ''
  if(/\D/.test(num) || num.length >= 14)
    return ''
  // 数字对应中文
  let zhArray = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九', '十'] 
  // 进位填充字符，第一位是 个位，可省略
  let baseArray = ['', '十', '百', '千', '万', '十', '百', '千', '亿', '十', '百', '千', '万'] 
  // 把数字切割成数组并倒序排列，然后进行遍历转成中文
  let string = String(num).split('').reverse().map((item, index) => { 
      // 如果当前位为0，直接输出数字， 否则输出 数字 + 进位填充字符
      item = Number(item) == 0 ? zhArray[Number(item)] : zhArray[Number(item)] + baseArray[index]
      return item
  }).reverse().join('') // 倒叙回来数组，拼接成字符串
  // 如果以 一十 开头，可省略一
  string = string.replace(/^一十/, '十') 
  string = string.replace(/十零/, '十')   
  // 如果有多位相邻的零，只写一个即可
  string = string.replace(/零+/, '零')  
  return string
}

//文件字节数单位转换,1000M内显示Ｍ,1000M以上显示G  toFixed(2)
export function toFileSize(num) {
  if (num == '') { 
    return '' 
  }
  var num1 = parseInt(num) / 1024 / 1024
  if (num1 < 1) { 
    var n = (parseInt(num) / 1024 ).toFixed(0)  
    return n + 'K'
  }
  var num2 = num1 / 1024
  if (num2 < 1) {    
    return num1.toFixed(2) + 'M'
  } else {
    return num2.toFixed(2) + 'G'
  }
}

// 数字千分位小数位表示
export function formatCurrency(number, num) {
  if (!number) {
    return '0.00'
  }
  // 使用正则表达式将数字转换成千分位格式
  return number.toFixed(num).replace(/\d(?=(\d{3})+\.)/g, '$&,')
}